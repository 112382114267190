import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  AuditIntakeState,
  clearAuthUser,
  OnlineAccountWrapperApplicationState,
  OnlineAccountWrapperStateKey
} from "@pie/online-account-externals";
import { componentDestroyed } from "@stores/root/actions";
import { Audit } from "@pie/shared-screens-audit/src";
import { LOCATION_CHANGE } from "connected-react-router";
import { appName } from "@utils/constants";

export const initialState: AuditIntakeState = {
  audit: undefined
};

const sliceName = `${appName}/${OnlineAccountWrapperStateKey.AUDIT_INTAKE}`;
const auditIntake = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    auditIntakeCallFinished: (state: AuditIntakeState, action) => {
      return {
        audit: { ...action.payload }
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(LOCATION_CHANGE, () => initialState);
    builder.addCase(componentDestroyed, () => initialState);
    builder.addCase(clearAuthUser, () => initialState);
  }
});

export const selectAuditState = createSelector(
  (state: OnlineAccountWrapperApplicationState) =>
    state[OnlineAccountWrapperStateKey.AUDIT_INTAKE],
  auditIntake => auditIntake
);

export type SaveAuditPayload = {
  policyNumber: string;
  audit: Partial<Audit>;
};

export const getAuditIntake = createAction<string>(
  "onlineaccountwrapperui/auditIntake/getAuditIntake"
);

export const auditIntakeActions = {
  ...auditIntake.actions,
  getAuditIntake
};

export default auditIntake.reducer;
