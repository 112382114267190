import { AuditAnalyticsEvents } from "@pie/shared-screens-audit/src";

export enum AdditionalAuditAnalyticEvents {
  HANDLE_LEAVE_MODAL_CONFIRM = "handleLeaveModalConfirm",
  HANDLE_LEAVE_MODAL_CANCEL = "handleLeaveModalCancel",
  BACK_TO_ACCOUNT_CLICKED_ON_CONFIRMATION_SCREEN = "backToAccountClickedConfirmationScreen",
  BACK_TO_ACCOUNT_CLICKED_ON_WELCOME_SCREEN = "backToAccountClickedWelcomeScreen",
  BACK_TO_ACCOUNT_CLICKED_ON_FORM_SCREEN = "backToAccountClickedFormScreen"
}

export const auditIntakeMap = new Map<string, string>([
  [
    `${AuditAnalyticsEvents.ADDITIONAL_DETAILS_SECTION_SAVED}`,
    "Next Button in Additional Details Section"
  ],
  [
    `${AuditAnalyticsEvents.BUSINESS_INFORMATION_SECTION_SAVED}`,
    "Next Button in Business Section"
  ],
  [
    `${AuditAnalyticsEvents.CONTRACTORS_SECTION_SAVED}`,
    "Next Button in Contractors and Subcontractors Section"
  ],
  [
    `${AuditAnalyticsEvents.EMPLOYEE_INFORMATION_SECTION_SAVED}`,
    "Next Button in Employee Information Section"
  ],
  [
    `${AuditAnalyticsEvents.OWNERS_AND_OFFICERS_SECTION_SAVED}`,
    "Next Button in Owners and Officers Section"
  ],
  [
    `${AuditAnalyticsEvents.PAYROLL_VERIFICATION_SECTION_SAVED}`,
    "Next Button in Payroll Verification and Documents Section"
  ],
  [
    `${AdditionalAuditAnalyticEvents.HANDLE_LEAVE_MODAL_CONFIRM}`,
    "Leave Page Button on Unsaved Data Confirmation Modal"
  ],
  [
    `${AdditionalAuditAnalyticEvents.HANDLE_LEAVE_MODAL_CANCEL}`,
    "Cancel Button on Unsaved Data Confirmation Modal"
  ],
  [
    `${AdditionalAuditAnalyticEvents.BACK_TO_ACCOUNT_CLICKED_ON_CONFIRMATION_SCREEN}`,
    "Back to Account Button on Confirmation Screen"
  ],
  [
    `${AdditionalAuditAnalyticEvents.BACK_TO_ACCOUNT_CLICKED_ON_WELCOME_SCREEN}`,
    "Back to Account Button on Welcome Screen"
  ],
  [
    `${AdditionalAuditAnalyticEvents.BACK_TO_ACCOUNT_CLICKED_ON_FORM_SCREEN}`,
    "Back to Account Button on Form Screen"
  ],
  [
    `${AuditAnalyticsEvents.SUBMIT_BUTTON_CLICKED}`,
    "Submit Button on Form Screen"
  ],
  [
    `${AuditAnalyticsEvents.CONTINUE_BUTTON_CLICKED}`,
    "Continue Button on Welcome Screen"
  ]
]);
