import React from "react";
import { AppBootstrap } from "@components/AppBootstrap";
import { ErrorBoundaryProvider } from "@components/ErrorBoundary";
import microApp from "@micro-zoe/micro-app";
import ReactDOM from "react-dom";
import config from "./config";
import { history } from "@stores/createStore";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider
} from "@material-ui/core";
import { AnalyticsProvider, SettingsProvider } from "@pie/components";
import { ConnectedRouter } from "connected-react-router";
import { theme } from "./theme";
import { datadogRum } from "@datadog/browser-rum";
import { OnlineAccountWrapperProvider } from "@pie/online-account-externals";

import "./index.css";
import { useLocation } from "react-router";
import { Drawer } from "@pie/drawer-molecule";

const importRegex = /import\s*(?:(?:(?:.*)\s*from\s*))(?:(?:"(.*?)"));?/g;

microApp.start({
  plugins: {
    global: [
      {
        loader(code: string) {
          code = code.replace(importRegex, all => {
            return all.replace(
              /\/microui\/(.*?)\//,
              (all, $1) =>
                // @ts-ignore
                `${config[$1]}/microui/${$1}/`
            );
          });
          return code;
        }
      }
    ]
  }
});

const rootElement = document.getElementById("app");

export const onlineAccountWrapperClassNameGenerator = createGenerateClassName({
  seed: "online-account-wrapper-ui"
});

const App: React.FC = ({ children }) => {
  const location = useLocation();

  return (
    <AnalyticsProvider location={{ ...location, key: location.key || "" }}>
      <ThemeProvider theme={theme}>
        <SettingsProvider
          onLoadError={console.error}
          defaultSettings={{}}
          onSuccess={({ datadogConfiguration }): void => {
            if (datadogConfiguration) {
              datadogRum.init({
                ...datadogConfiguration,
                defaultPrivacyLevel: "mask-user-input",
                enableExperimentalFeatures: ["feature_flags"],
                sessionReplaySampleRate: 100,
                sessionSampleRate: 100,
                trackUserInteractions: true
              });
              datadogRum.startSessionReplayRecording();
            }
          }}
          url="/appsettings.json"
        >
          <OnlineAccountWrapperProvider>
            {children}
          </OnlineAccountWrapperProvider>
          <Drawer />
        </SettingsProvider>
      </ThemeProvider>
    </AnalyticsProvider>
  );
};

const renderApp = (Component: React.FC): void => {
  ReactDOM.render(
    <StylesProvider generateClassName={onlineAccountWrapperClassNameGenerator}>
      <ErrorBoundaryProvider>
        <ConnectedRouter history={history}>
          <App>
            <Component />
          </App>
        </ConnectedRouter>
      </ErrorBoundaryProvider>
    </StylesProvider>,
    rootElement
  );
};

renderApp(AppBootstrap);
