import React, { FC } from "react";
import { Modal } from "@pie/core-ui-modal";

import { ModalText } from "./styles";
import { MODAL_TITLE } from "./constants";
import { LeavePageModalProps } from "./types";

export const LeavePageModal: FC<LeavePageModalProps> = props => (
  <Modal
    cancelText="Cancel"
    confirmText="Leave Page"
    onClose={props.handleClose}
    onCancel={props.handleCancel}
    onConfirm={props.handleConfirm}
    onOpenChange={props.handleClose}
    open={props.showModal}
    title={MODAL_TITLE}
  >
    <ModalText>
      Your progress in any unfinished sections will be lost if you navigate away
      from this page. Please ensure all sections are completed before leaving.
    </ModalText>
  </Modal>
);
