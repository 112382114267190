import React, { FC, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  analyticsAction,
  AnalyticsClickName
} from "@pie/online-account-externals";
import { useAuth } from "@pie/components";
import { LeavePageModal } from "@components/LeavePageModal";

import { leaveModalActions } from "@stores/leaveModal/reducer";
import { selectIsLeaveModalOpen } from "@stores/leaveModal";
import { selectAuditState } from "@stores/auditIntake";
import { AuditStatus } from "@pie/shared-screens-audit";
import { PROMPT_ROUTES } from "./constants";
import {
  AdditionalAuditAnalyticEvents,
  auditIntakeMap
} from "@components/AuditIntake/types";

export const SignOutPrompt: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = useAuth();
  const { audit } = useSelector(selectAuditState);
  const isLeaveModalOpen = useSelector(selectIsLeaveModalOpen);

  const shouldPromptInCurrentRoute = PROMPT_ROUTES.includes(
    history.location.pathname
  );
  const isAuditInProgress = audit?.status === AuditStatus.IN_PROGRESS;
  const shouldPrompt = shouldPromptInCurrentRoute && isAuditInProgress;

  const handleClose = () => {
    dispatch(leaveModalActions.hideModal());
  };

  const handleCancel = () => {
    const actionName = auditIntakeMap.get(
      AdditionalAuditAnalyticEvents.HANDLE_LEAVE_MODAL_CANCEL
    );
    actionName &&
      dispatch(
        analyticsAction({
          actionName: actionName,
          type: "Click"
        })
      );
    dispatch(leaveModalActions.hideModal());
  };

  const handleConfirm = () => {
    if (shouldPrompt) {
      const actionName = auditIntakeMap.get(
        AdditionalAuditAnalyticEvents.HANDLE_LEAVE_MODAL_CONFIRM
      );
      actionName &&
        dispatch(
          analyticsAction({
            actionName: actionName,
            type: "Click"
          })
        );
    }
    handleClose();
    const actionName = shouldPrompt
      ? "Sign Out on Audit screen"
      : AnalyticsClickName.SIGN_OUT;
    dispatch(
      analyticsAction({
        actionName: actionName,
        type: "Click",
        options: ["trackRoute"]
      })
    );

    logout();
  };

  useEffect(() => {
    // Call the confirm action immediately when then sign out process
    // is started but the prompt is no required
    if (isLeaveModalOpen && !shouldPrompt) {
      handleConfirm();
    }
  }, [isLeaveModalOpen, shouldPrompt]);

  if (isLeaveModalOpen && shouldPrompt) {
    return (
      <LeavePageModal
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        handleClose={handleClose}
        showModal
      />
    );
  }

  return null;
};
